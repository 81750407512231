import React, { useState, forwardRef, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box, Grid, Typography } from '@mui/material';
import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Footer from './Footer';

import Image from '../../components/Image';

import { getGameCategsAndCompanies, getSiteSettings, getUserMemberBalance } from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { openTransactionsDialog } from '../../redux/slices/transactionsDialog';

import { setGameCategories } from '../../redux/slices/game';

import DepositWithdrawDialog from './header/DepositWithdrawDialog';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import WithdrawFormDialog from './header/withdraw/WithdrawFormDialog';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings, isLoadingPage } = useSelector((x) => ({ ...x.lookup, ...x.settings }));

  const isVisible = usePageVisibility();

  const { translate, currentLang } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const params = useParams();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  useEffect(() => {
    const toFetch = [getSiteSettings(), getAllGames(), getGameCategsAndCompanies()];
    if (isAuthenticated) toFetch.push(getUserMemberBalance());

    dispatch(openLoadingScreen());
    Promise.all(toFetch).finally(() => dispatch(closeLoadingScreen()));
  }, [currentLang]);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    if (siteSettings?.config_analytics) {
      const _ga = siteSettings?.config_analytics?.split(',');

      // Multiple products (previously known as trackers)
      ReactGA.initialize(
        _ga.map((item) => ({
          // measurement ID
          trackingId: item,
          // gaOptions: {...}, // optional
          // gtagOptions: {...}, // optional
        }))
        //   [
        //   {
        //     // measurement ID
        //     trackingId: _ga[0],
        //     // gaOptions: {...}, // optional
        //     // gtagOptions: {...}, // optional
        //   },
        // ]
      );
    }

    if (siteSettings?.config_livechat) {
      const respondIo = JSON.parse(siteSettings?.config_livechat);
      const script = document.createElement('script');

      script.id = respondIo?.id;
      script.addEventListener('load', () => {
        const el = document.getElementById('_68625');

        if (el) {
          el.setAttribute('style', 'z-index:1200 !important');
        }
      });
      script.src = respondIo?.src;
      script.async = true;

      document.head.appendChild(script);
    }

    if (siteSettings?.config_fb_pixel) {
      const metaPixel = JSON.parse(siteSettings?.config_fb_pixel);
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };

      if (Array.isArray(metaPixel)) {
        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      } else {
        ReactPixel.init(metaPixel, options);

        ReactPixel.pageView(); // For tracking page view
      }
    }
  }, [siteSettings]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;

        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        backgroundColor: '#0b1712',
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
      />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
        <Footer />
      </MainStyle>

      <Box
        sx={{
          width: '100%',
          height: '64px',
          backgroundColor: '#051a12',
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 100,
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          display: 'block',
          p: '10px 0',
          right: 0,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            {/* <Iconify
              icon={'fluent:share-24-filled'}
              width={24}
              height={24}
              sx={{ color: '#2283f6' }}
              className="share-button"
              onClick={() => navigate('invite')}
            />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              {translate('share')}
            </Typography> */}
          </Grid>

          <Grid
            onClick={() => navigate('/')}
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            <Iconify icon={'ph:game-controller-fill'} width={24} height={24} sx={{ color: '#2283f6' }} />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              {translate('games')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            <Box
              onClick={() =>
                dispatch(
                  openTransactionsDialog({
                    open: true,
                    isDeposit: true,
                  })
                )
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'linear-gradient(rgb(66, 145, 241) 0px, rgb(34, 131, 246) 100%)',
                border: '6px solid #18202c',
                flexDirection: 'column',
                height: '68px',
                marginTop: '-20px',
                width: '68px',
                borderRadius: '100%',
                cursor: 'pointer',
              }}
            >
              <Image
                src={require('../../assets/home/wallet.gif')}
                alt="Deposit"
                sx={{ height: '24px', width: '24px', objectFit: 'contain' }}
              />
              <Typography
                sx={{ fontFamily: 'sans-serif', fontSize: '12px', fontWeight: 700, color: '#fff' }}
                className="deposit-text-animation"
              >
                {translate('deposit')}
              </Typography>
            </Box>
            {/* <Iconify icon={'fluent:share-24-filled'} width={24} height={24} sx={{ color: '#2283f6' }} />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              Share
            </Typography> */}
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
            onClick={() => navigate('promotion')}
          >
            <Iconify icon={'healthicons:money-bag'} width={24} height={24} sx={{ color: '#2283f6' }} />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              {translate('bonus')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            {/* <Iconify icon={'solar:download-outline'} width={24} height={24} sx={{ color: '#2283f6' }} />
            <Typography
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              App
            </Typography> */}
          </Grid>
        </Grid>
      </Box>

      {/* {isOpenCall && <CallClient isOpenCall={isOpenCall} handleCloseCall={handleCloseCall} />} */}

      {/* <Hardware isOpenHardware={isOpenHardware} handleCloseHardware={handleCloseHardware} /> */}

      {isOpenTransactionsDialog.open && <DepositWithdrawDialog />}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}
    </Box>
  );
};

export default DashboardLayout;
